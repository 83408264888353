const urls = require('./urls');

export async function createService(clientId, companyId, totalCost, dateTime){
    try{   
        const url = urls.API_URL + "services/post"
        console.log(url);
        const response = await fetch( url,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin': '*' 

            },
            body:JSON.stringify({
                emailClient: clientId,
                emailCompany: companyId,
                totalCost:totalCost,
                dateAndTime: dateTime,
                state: 'Pending',
            })
        });
        
        const data = await response.json();
        if(response.status === 200){
            return data;
        }
        else return 500;
    } catch(error){
        return error.message;
    }
}