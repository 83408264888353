const urls = require('./urls');

export async function updatePassword(email, password){
    try{   
        const url = urls.API_URL + "companies/updatePassword/" + email + '/' + password 
        const response = await fetch( url,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin': '*' 
            },
        });
        
        const data = await response.json();
        
        if(response.status === 200){
            return 200;
        }
        else return 500;
    } catch(error){
        return error.message;
    }
}

