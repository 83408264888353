import React, { useRef, useState } from "react";
import '../Styles/PasswordReset.css'
import refreshPwd from "../controllers/PasswordResetController";
import { useParams } from "react-router-dom";

export default function PasswordReset(){
    const [password, setPassword] = useState();
    const [checkPassword, setCheckPassword] = useState();
    const [equal, setEqual] = useState(true);
    const [error, setError] = useState(false);
    const [conditions, setConditions] = useState(true);
    const firstPwd = useRef();
    const secondPwd = useRef();
    const { token } = useParams();

    const checkEqual = () =>{
        setCheckPassword(secondPwd.current.value);

        if(secondPwd.current.value !== password) setEqual(false);
        else setEqual(true)
    }   

    const passwordChecks = () =>{
        setPassword(firstPwd.current.value);
        const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/
        
        if(!firstPwd.current.value.match(passwordFormat)){
            setConditions(false);
        } else {
            setConditions(true);
        }
    }

    const submit = () =>{
        if(password === checkPassword){
            const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/
            if(firstPwd.current.value.match(passwordFormat)){
                refreshPwd(token, firstPwd.current.value).then(()=>{
                    window.location.replace("http://localhost:3000")
                }).catch(()=>{
                    setError(true);
                });
            } else{
                setConditions(false);
            }
        } else {
            setEqual(false);
        }
    }

    return(
        <section class="vh-100" style={{backgroundColor: "#508bfc"}}>
            <div class="container py-5 h-100">
                <div class="row d-flex justify-content-center align-items-center h-100">
                    <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div class="card shadow-2-strong" style={{borderRadius: '1rem'}}>
                            <div class="card-body p-5 text-center">
                                <h3 class="mb-5">Reset Password</h3>
                                {equal ? 
                                    <div>
                                    </div>:
                                    <div className="align-middle bg-danger rounded w-60 h-20">
                                        <h5 className="text-light">Password do not match</h5>
                                    </div>
                                }
                                {conditions ? 
                                    <div>
                                    </div>:
                                    <div className="align-middle bg-danger rounded w-60 h-20">
                                        <h5 className="text-light">Password must have 1 capital letter and 1 special character</h5>
                                    </div>
                                }
                                {error ? 
                                    <div className="align-middle bg-danger rounded w-60 h-20">
                                        <h5 className="text-light">An error has occurred</h5>
                                    </div>:
                                    <div></div>
                                }
                                <div class="form-outline mb-4">
                                    <input onChange={passwordChecks} ref={firstPwd} placeholder="New Password" type="password" class="form-control form-control-lg" />
                                </div>

                                <div class="form-outline mb-4">
                                    <input onChange={checkEqual}ref={secondPwd} placeholder="Repeat Password" type="password" class="form-control form-control-lg" />
                                </div>

                                <button onClick={submit} class="btn btn-primary btn-lg btn-block" type="submit">Update Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
    
}