import React, { useEffect, useRef, useState } from "react";
import "../Styles/CompanyProfile.css";
import { HiKey } from 'react-icons/hi';
import Cookies from 'universal-cookie';
import { updatePassword } from "../controllers/CompanyController";


export default function CompanyProfile(){
    const [companyEmail, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const pwdRef = useRef(null);
    const [conditions, setConditions] = useState(true);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(()=>{
        const cookies = new Cookies();
        const cookie = cookies.get('spanner-email');
        setEmail(cookie.name);
        console.log(cookie)
    }, []);

    const handlePassword = () =>{
        setPassword(pwdRef.current.value)
    }

    const changePwd = async () =>{
        const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/
        
        if(!pwdRef.current.value.match(passwordFormat)){
            setConditions(false);
            setSuccess(false);

        } else {
            setConditions(true);
            
            const ret = await updatePassword(companyEmail, password)

            if(ret === 200){
                setSuccess(true);
            } 

            if(ret === 500){
                setErrorMsg("Network Error. Please try again");
                setError(true);
                setSuccess(false);
            }
        }
    }



    return(
        <div className="vh-100" style={{backgroundColor: "#C8C8C8"}}>
           <div class="row d-flex justify-content-center align-items-center h-100">
                    <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div class="card shadow-2-strong" style={{borderRadius: '1rem'}}>
                            <div class="card-body p-5 text-center">
                                <h1>Profile</h1>
                                <div class="form-outline mb-4">
                                    <h2>{companyEmail}</h2>
                                </div>
                                {error ? 
                                    <div className="align-middle bg-danger rounded h-20">
                                        <h5 className="text-light">{ errorMsg }</h5>
                                    </div>:
                                    <div></div>
                                }
                                {success ? 
                                    <div className="align-middle bg-success rounded h-20">
                                        <h5 className="text-light">Email changed succesfully</h5>
                                    </div>:
                                    <div></div>
                                }
                                {
                                    !conditions ? 
                                    <div className="align-middle bg-danger rounded h-20">
                                        <h5 className="text-light">Wrong Password Format</h5>
                                    </div>:
                                    <div></div>
                                }
                                <div class="form-outline mb-4">
                                    <HiKey
                                        color=""
                                        className="icon"
                                    />
                                    <input type="password" className="spanner-input" placeholder="Password" ref={pwdRef} onChange={handlePassword}/>
                                    <button onClick={changePwd} className="spanner-button">Change Password</button>
                                </div>
                                <div class="form-outline mb-4">
                                    <p>For changing any other field please contact us via email at hello@hello.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}