const urls = require('./urls');

export default async function getUser(email, password){
    try{   
        const url = urls.API_URL + "/users/getUserById/" + email
        const response = await fetch( url,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin': '*' 
            },
        });
        
        const data = await response.json();

        if(response.status === null) return 500
        else if(response.status === 402) return 402;
        else if(response.status === 404) return 404;
        else if(response.status === 200) return data;
        else return 500;

    } catch(error){
        throw error.message;
    }
    
}