import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import CompanyChat from "./CompanyChat";
import CompanyServices from "./CompanyServices";
import '../Styles/CompanyPage.css';
import { CgProfile } from 'react-icons/cg';
import { BsChatLeftFill } from 'react-icons/bs';
import { GiSpanner } from 'react-icons/gi';


export default function CompanyPage(){

    useEffect(()=>{
        const cookies = new Cookies();
        const cookie = cookies.get('Spanner');
        if(cookie == null || cookie == undefined){
            window.location.replace("http://localhost:3000/company-login")
        }
    },[]);

    const [chatPage, setChatpage] = useState(true);

    const goToProfile = () =>{
        window.location.replace("http://localhost:3000/company-profile");
    }

    return(
        <div className="vh-100" style={{backgroundColor: "#fff", overflow:'hidden'}}>
            <div className="row">
                <div className="justify-content-center col-1 ">
                    <div>
                        <div>
                            <img src={require('../Imgs/logo.png')} alt='logo' className="logo"/>
                        </div>
                        <div>
                            <button style={{all:'unset'}} onClick={goToProfile}>
                                <CgProfile
                                    color="#011468"
                                    className="icon-image"
                                />
                            </button>
                        </div>
                        <div>
                            <button style={{all:'unset'}} onClick={()=>{setChatpage(true)}}>
                                <BsChatLeftFill
                                    color="#011468"
                                    className="icon-image"
                                />
                            </button>
                        </div>
                        <div>
                            <button style={{all:'unset'}} onClick={()=>{setChatpage(false)}}>
                                <GiSpanner
                                    color="#011468"
                                    className="icon-image"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                {chatPage ? <CompanyChat/> : <CompanyServices/>}
                
            </div>
        </div>
    );
}