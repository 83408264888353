const USER_ALREADY_EXISTST = "User already exists.";
const USER_NOT_FOUND = "User doesn't exists. Please Sign Up.";
const WRONG_PASSWORD = "Password does not match.";
const INTERNAL_SERVER_ERROR = "Error in the server.";
const ERROR_UPDATING_PASSWORD = "Error updatig password."
const ERROR_REMOVING_USER = "Error deleting user.";
const ERROR_USERS_MAIL_BLANK = "User email must have value.";
const ERROR_USERS_NAME_BLANK = "User name must have value.";
const ERROR_USERS_PASSWORD_BLANK = "User password must have value.";
const ERROR_PASSWORD_FORMAT = "Wrong password format, the password must be 8 charcaters, contain an uppercase password, a number, and a special character";
const ERROR_EMAIL_FORMAT = "Enter a valid email";

module.exports = {
    USER_ALREADY_EXISTST,
    USER_NOT_FOUND,
    WRONG_PASSWORD,
    INTERNAL_SERVER_ERROR,
    ERROR_UPDATING_PASSWORD,
    ERROR_REMOVING_USER,
    ERROR_USERS_MAIL_BLANK,
    ERROR_USERS_NAME_BLANK,
    ERROR_USERS_PASSWORD_BLANK,
    ERROR_EMAIL_FORMAT,
    ERROR_PASSWORD_FORMAT
}