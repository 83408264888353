import React, { useEffect, useState } from "react";
import { Rating } from 'react-simple-star-rating'
import '../Styles/CompanyServices.css'

export default function CompanyServices(){

    const service = [
        {
            personName: "Pere",
            state: "Pending",
            image: '../Imgs/test.png',
            starRating:3.5
        },
        {
            personName: "Pere 1",
            state: "Active",
            image: '../Imgs/test.png',
            starRating:3.5
        },
        {
            personName: "Pere 2",
            state: "Pending",
            image: '../Imgs/test.png',
            starRating:3.5
        },
        {
            personName: "Pere 3",
            state: "Active",
            image: '../Imgs/test.png',
            starRating:3.5
        },
        {
            personName: "Pere 4",
            state: "Pending",
            image: '../Imgs/test.png',
            starRating:3.5
        },
        {
            personName: "Pere 5",
            state: "Active",
            image: '../Imgs/test.png',
            starRating:3.5
        }
    ]

    const [active, setActive] = useState('active');
    const [filteredList, setFilteredList] = useState([]);

    useEffect(()=>{
        var pendingList = [];
        for(var i = 0; i < service.length; ++i){
            if(service[i].state == 'Pending'){
                pendingList.push(service[i]);
            }
        }

        setFilteredList(pendingList);
    }, [])

    return(
        <div className="screen-container vh-100 col-11 " style={{backgroundColor: "#C8C8C8", borderTopLeftRadius:20, borderBottomLeftRadius:20}}>
            <div className="button-div">
                <button className={active === 'pending' ? "button-active" : "button-inactive"} onClick={() => setActive('pending')}>
                    Pending Services
                </button>
                <button className={active !== 'pending' ? "button-active" : "button-inactive"} onClick={() => setActive('all')}> 
                    All Services
                </button>
            </div>
            {
                active==='pending' ?
                <div className="service-container">
                    {
                        filteredList.map((item)=>{
                            return <div className="service-box">
                                        <p className="name-text">{item.personName}</p>
                                        <p className="service-state-text">{item.state}</p>
                                        <div className="service-image-conatiner">
                                            <img className="service-image" src={require('../Imgs/test.png')} alt='service image'/>
                                        </div>                            
                                        
                                        <div className="rating-container">
                                            <Rating
                                                initialValue={item.starRating}
                                                className="star-rating"
                                                size={20}
                                                fillColor="#011468"
                                                readonly={true}
                                            />
                                        </div>
                                    </div>
                        })
                    }
                </div>
                :
                <div className="service-container">
                    {
                        service.map((item)=>{
                            return <div className="service-box">
                                        <p className="name-text">{item.personName}</p>
                                        <p className="service-state-text">{item.state}</p>
                                        <div className="service-image-conatiner">
                                            <img className="service-image" src={require('../Imgs/test.png')} alt='service image'/>
                                        </div>                            
                                        
                                        <div className="rating-container">
                                            <Rating
                                                initialValue={item.starRating}
                                                className="star-rating"
                                                size={20}
                                                fillColor="#011468"
                                                readonly={true}
                                            />
                                        </div>
                                    </div>
                        })
                    }
                </div>
            }   
        </div>
    );
}