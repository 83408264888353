import './App.css';
import  React  from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Error from './components/Error'
import RegisterCompany from './components/RegisterCompany';
import PasswordReset from './components/PasswordReset';
import AdminPage from './components/AdminPage';
import CompanyLogIn from './components/CompanyLogIn';
import CompanyPage from './components/CompanyPage';
import CompanyProfile from './components/CompanyProfile';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';

function App() {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<LandingPage/>}/>
        <Route exact path='/register-company' element={<RegisterCompany/>}/>
        <Route exact path='/admin-page' element={<AdminPage/>}/>
        <Route exact path='/password-reset/:token' element={<PasswordReset/>}/>
        <Route exact path='/company-login' element={<CompanyLogIn/>}/>
        <Route exact path='/company-page' element={<CompanyPage/>}/>
        <Route exact path='/company-profile' element={<CompanyProfile/>}/>
        <Route exact path='/privacy-policy' element={<PrivacyPolicy/>}/>
        <Route exact path='/terms-conditions' element={<TermsAndConditions/>}/>
        <Route exact path='/*' element={<Error/>}/>
      </Routes>
    </>
  );
}

export default App;
