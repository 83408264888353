const urls = require('./urls');

export async function getServiceById(serviceId){
    try{   
        const url = urls.API_URL + "services/getService/" + serviceId
        console.log(url);
        const response = await fetch( url,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin': '*' 
            },
        });
        
        const data = await response.json();
        console.log(data);
        if(response.status === 200){
            return data;
        }
        else return 500;
    } catch(error){
        return error.message;
    }
}