import { useState, useEffect } from "react";
import { getServiceById } from "../controllers/GetServiceController";
import '../Styles/ServiceBox.css';
import { AiOutlineCheck } from 'react-icons/ai'
import { RxCross1 } from 'react-icons/rx'

export default function ServiceBox ({ serviceId }){
    const [service, setService] = useState({});
    const [loading, setLoading] = useState(true);
    const [dateTime, setDateTime] = useState(new Date());

    useEffect(()=>{
        const init = async () =>{
            const serviceAux = await getServiceById(serviceId);
            setService(serviceAux);
            const fullDate = new Date(serviceAux.dateAndTime);
            setDateTime(fullDate);

        }

        init();
        setLoading(false);
    }, [])
    
    if(loading){
        return(
            <div>
                <p>Cargando...</p>
            </div>
        )
    }

    const getDate = ()=> {
        return dateTime.getDay() + '/' + dateTime.getMonth() + '/' + dateTime.getFullYear() 
    }
    const getTime = () => {
        var minutes;
        if(dateTime.getMinutes() < 10){
            minutes = '0' + dateTime.getMinutes();
        } else{
            minutes = dateTime.getMinutes();
        }

        return dateTime.getHours() + ':' + minutes;
    }

    if(service.state === 'Accepted'){
        return(
            <div className="service-accept-container">
                <p className="service-date">{getDate()}</p>
                <p className="service-hour">{getTime()}</p>
            </div>
        )
    }

    if(service.state === 'Denied'){
        return(
            <div className="service-denied-container">
                <p className="service-date">{getDate()}</p>
                <p className="service-hour">{getTime()}</p>
            </div>
        )
    }

    return(
        <div className="service-pending-container">
            <p className="service-date">{getDate()}</p>
            <p className="service-hour">{getTime()}</p>
        </div>
    )
}