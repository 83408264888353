import React, { useEffect, useRef, useState } from "react";
import '../Styles/CompanyChat.css'
import { RxAvatar } from 'react-icons/rx';
import { FaPlus } from 'react-icons/fa';
import { getMessagesByChat } from "../controllers/MessageController";
import { getChatByCompany } from "../controllers/ChatController";
import { saveMessageDB } from "../controllers/SaveMesageController";
import { saveMessageServiceDB } from "../controllers/SaveServiceController";
import Cookies from 'universal-cookie';
import ChatBox from "./ChatBox";
import getUser from "../controllers/UserController";
import { io } from 'socket.io-client';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { createService } from "../controllers/ServiceController";
import ServiceBox from "./ServiceBox";

export default function CompanyChat(){
    const [chatList, setChatList] = useState([]);
    const [activeChat, setActiveChat] = useState({});
    const [messages, setMessages] = useState([]);
    const [user, setUser] = useState("");
    const [selectedUser, setSelectedUser] = useState('');
    const [text, setText] = useState('');
    const [arrivalMsg, setArrivalMsg] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [serviceDate, setDate] = useState(null)
    const [serviceAmount, setAmount] = useState(null);
    const [serviceHour, setHour] = useState(null)
    const socket = useRef();
    const msgInput = useRef(null)
    const scrollRef = useRef();
    const dateRef = useRef();
    const hourRef = useRef();
    const amountRef = useRef();

    useEffect(()=>{
        const init = async () => {
            const messagesData = await getMessagesByChat(activeChat);
            await setMessages(messagesData);
        }

        init();
    }, [arrivalMsg])

    useEffect(()=>{
        scroll2bottom();
    }, [messages]);

    useEffect(()=>{
        const init = async () => {
            const cookies = new Cookies();
            const cookie = cookies.get('spanner-email');
            setUser(cookie.email)

            const chats = await getChatByCompany(cookie.email);
            setChatList(chats);

            const friend = chats[0].members.find((m) => m !== user);
            const friendInfo = await getUser(friend);
            setSelectedUser(friendInfo.name);

            setActiveChat(chats[0]); 

            const messagesData = await getMessagesByChat(chats[0]);
            setMessages(messagesData);
        }            

        init()
    }, []);
    
    useEffect(()=>{
        socket.current = io("ws://localhost:8900")
        socket.current.on("getMessage", (data) => {
            setArrivalMsg(data.text)
        })
    }, []);
    
    useEffect(()=>{
        socket.current.emit("addUser", user)
        socket.current.on("getUsers", users=>{
            console.log(users)
        })

    },[user]);


    const changeChat = async (name, friendId) =>{
        const chat = await getActiveChat(friendId, user);
        await setActiveChat(chat)

        const messagesData = await getMessagesByChat(chat);
        await setMessages(messagesData);
        await setSelectedUser(name);
    }

    const getActiveChat = async (friendId, user) => {
        for(var i = 0; i < chatList.length; ++i){
            if((chatList[i].members[0] === friendId || chatList[i].members[0] === user) 
            && (chatList[i].members[1] === friendId || chatList[i].members[1] === user)){
                return chatList[i]
            }
        }

    }

    const scroll2bottom = () =>{
        scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }

    const saveMessage = async (e) =>{
        if(e.key === 'Enter'){
            const recieverId = activeChat.members.find(member => member !== user);

            socket.current.emit("sendMessage",{
                senderId: user,
                recieverId,
                text: text
            })

            await saveMessageDB(user, text, activeChat._id);
            setText('');
            msgInput.current.value = '';

            const messagesData = await getMessagesByChat(activeChat);
            await setMessages(messagesData);
        }

        scroll2bottom();
    }

    const saveService = async () =>{
        
            var date = new Date(serviceDate + ' ' + serviceHour);
            const recieverId = activeChat.members.find(member => member !== user);
            
            if(date < Date.now()){
                alert('La fecha tiene que ser posterior a ahora');
            } else{
                const serviceId = await createService(user, recieverId, serviceAmount, date);
            
                saveMessageServiceDB(user, activeChat._id, serviceId);
                setShowModal(false); 
                const messagesData = await getMessagesByChat(activeChat);
                await setMessages(messagesData);
                
            }
        
    }

    return(
        <div className="vh-100 col-11" style={{backgroundColor: "#E4E4E4", borderTopLeftRadius:20, borderBottomLeftRadius:20}}>
            <div className="row">
                <div className="col-2" style={{overflowX:'hidden', overflowY: 'scroll'}}>
                    {
                        chatList.map((item) =>{
                            const friend = item.members.find((m) => m !== user);
                            return(
                               <ChatBox key={friend} friendId={friend} changeChat={changeChat}/>
                            )
                        })
                    }
                </div>
                <div className="vh-100 col-10" style={{backgroundColor: "#C8C8C8", borderTopLeftRadius:20, borderBottomLeftRadius:20}}>
                    <div className="top-box-in-chat">
                        <RxAvatar
                            className="top-box-icon"
                            color="#011468"
                        />
                        <p className="person-name-top-box">{selectedUser}</p>
                    </div>
                    {
                        showModal && 
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Concertar Cita</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modal-container">
                                    <p className="modal-text">
                                        Por favor introduce en que hora y fecha se realizara la visita
                                    </p>
                                
                                    <input className="modal-date" type="date" ref={dateRef} onChange={()=> setDate(dateRef.current.value)}/>
                                    <input className="modal-time" type="time" ref={hourRef} onChange={()=> setHour(hourRef.current.value)}/>
                                    <input className="modal-amount" type="number" placeholder="Enter the payment amount" ref={amountRef} onChange={()=> setAmount(amountRef.current.value)}/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={saveService}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    }
                    <div className="message-container">
                        {   
                            messages.map((item)=>{
                                if(item.isService){
                                    return(
                                        <ServiceBox serviceId={ item.serviceId }/>
                                    )
                                }
                                else{
                                    const mine = item.sender === user;
                                    if(!mine){
                                        return(
                                            <div className="reciever-container">
                                                <p className="reciever-text">{item.text}</p>
                                            </div>
                                        )
                                    } else{
                                        return(
                                            <div className="sender-container">
                                                <p className="sender-text">{item.text}</p>
                                            </div> 
                                        )
                                    }
                                }
                            }) 
                        }
                        <div ref={scrollRef}/>
                    </div>

                    <input ref={msgInput} onChange={()=> setText(msgInput.current.value)} onKeyDown={saveMessage} className="input-message" placeholder="Type a message"/>
                    <div className="send-service-button-container">
                        <button 
                            className="send-service-button"
                            onClick={() => setShowModal(true)}
                        >
                            <FaPlus
                                color="#FFF"
                                className="send-service-icon"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}