const urls = require('./urls');


export async function saveMessageServiceDB(sender, chatId, serviceId){
    try{  
        const url = urls.API_URL + "msg/save"
        const response = await fetch( url,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin': '*' 
            },
            body:JSON.stringify({
                sender: sender,
                text: "",
                chatId: chatId,
                isService: true,
                serviceId: serviceId,
            })
        });
        
        const data = await response.json();

        if(response.status === 200){
            return 200;
        }
        else return 500;
    } catch(error){
        return error.message;
    }
}