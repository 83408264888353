const urls = require('./urls');

export async function getChatByCompany(email){
    try{   
        const url = urls.API_URL + "chat/getChats/" + email 
        const response = await fetch( url,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin': '*' 
            },
        });
        
        const data = await response.json();

        if(response.status === 200){
            return data;
        }
        else return 500;
    } catch(error){
        return error.message;
    }
}