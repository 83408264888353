import React, { useEffect } from 'react';
import { AiFillInstagram } from 'react-icons/ai';
import { IoLogoTwitter } from 'react-icons/io';
import { AiFillLinkedin } from 'react-icons/ai';
import { GiStairsGoal } from 'react-icons/gi'
import { MdOutlineAppSettingsAlt } from 'react-icons/md'
import { MdEmojiPeople } from 'react-icons/md'
import '../Styles/LandingPage.css'

export default function LandingPage(){
    useEffect(()=>{
        window.onload = scrollToMiddle
    })

    function scrollToMiddle() {
        const scrollContainer = document.getElementById('scrollContainer');
        const scrollContent = document.getElementById('scrollContent');
    
        // Calculate the middle position of the scrollContent div
        const middlePosition = (scrollContent.scrollWidth - scrollContainer.clientWidth) / 2;
    
        // Scroll to the middle position horizontally
        scrollContainer.scrollLeft = middlePosition;
    }

    return(
        <div className="mobile-container container">
            <div className="row text-center" style={{marginTop:'20px'}}>
                <div className="col-md-5 my-auto text-center">
                    <h1 style={{ fontWeight: 'bold', color: '#011468' }}>SPANNER SERVICES</h1>
                    <h6 style={{ color: '#011468' }}>
                        Spanner Services is an application that, working as an intermediary with the client, has the objective of providing home services to the consumer and thus ensure greater and easier accessibility to what is needed.
                    </h6>
                    <div className='container-fluid' id='download-imgs' style={{ marginTop: '30px' }}>
                        <h2 className="text-center">Coming Soon!</h2>
                        {/* Uncomment this section if you want to include the download buttons */}
                        {/* <div className='row justify-content-center'>    
                            <div className="col-3"> 
                                <a href='https://play.google.com/'>
                                    <img src={require('../Imgs/google_play.png')} className="img-fluid float-left" style={{ height: 50 }} alt="mobile screen" />
                                </a>
                            </div>
                            <div className="col-3"> 
                                <a href='https://www.apple.com/es/app-store/'>
                                    <img src={require('../Imgs/apple_store.png')} className="img-fluid float-left" style={{ height: 50 }} alt="mobile screen" />
                                </a>                        
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="sign-in col-7">
                    <div className="images-and-sign-in  mobile-img">
                        <img src={require('../Imgs/Profile.png')} className="img-fluid float-left" style={{height:450, zIndex:-1, position:'relative', left:'10%'}} alt="mobile screen"/>
                        <img src={require('../Imgs/Home.png')} className="img-fluid float-center" style={{height:500}} alt="mobile screen"/>
                        <img src={require('../Imgs/Services.png')} className="img-fluid float-rigth" style={{height:450, zIndex:-1, position:'relative', right:'10%'}} alt="mobile screen"/>
                    </div>
                </div>
                
                <div id="scrollContainer" className="images-container computer-landing">
                    <div id="scrollContent" className="images-and-sign-in col-6 mobile-img">
                        <img src={require('../Imgs/Profile.png')} className="img-fluid float-left" style={{height:400, zIndex:-1, position:'relative', left:'9%'}} alt="mobile screen"/>
                        <img src={require('../Imgs/Home.png')} className="img-fluid float-center" style={{height:450}} alt="mobile screen"/>
                        <img src={require('../Imgs/Services.png')} className="img-fluid float-rigth" style={{height:400, zIndex:-1, position:'relative', right:'9%'}} alt="mobile screen"/>
                    </div>
                </div>
                <h3 className='d-md-none' style={{ color: '#011468' }}>
                    Scroll to see the screens
                </h3>
            </div>

            <div className="row text-center" style={{ marginTop: '50px' }} id='WhoWeAreAndWhatWeDo'>
                <div className="col-md-12 col-lg-4 mb-4">
                    <MdEmojiPeople size={75} style={{ marginBottom: 30, color: '#011468' }} />
                    <h6 style={{ fontWeight: 'bold', color: '#011468' }}>WHO WE ARE</h6>
                    <p style={{ color: '#011468', textAlign: 'justify' }}>
                        We are a group of young people with the intention of adapting home services to new technologies and thus achieving easier access for people to them through an application.
                    </p>
                </div>
                <div className="col-md-12 col-lg-4 mb-4">
                    <MdOutlineAppSettingsAlt size={75} style={{ marginBottom: 30, color: '#011468' }} />
                    <h6 style={{ fontWeight: 'bold', color: '#011468' }}>OUR APP</h6>
                    <p style={{ color: '#011468', textAlign: 'justify' }}>
                        At Spanner Services, we bring the population closer to all their needs with just one click. Anyone who needs it can easily contact all types of home services such as plumbers, carpenters, electricians, or cleaning services. In this way, we would be able to act as intermediaries between the consumer and the worker, creating benefits for both parties.
                    </p>
                </div>
                <div className="col-md-12 col-lg-4">
                    <GiStairsGoal size={75} style={{ marginBottom: 30, color: '#011468' }} />
                    <h6 style={{ fontWeight: 'bold', color: '#011468' }}>GOALS</h6>
                    <p style={{ color: '#011468', textAlign: 'justify' }}>
                        <b>Quality of Service:</b> Measure and improve the quality of services provided through your platform. You can set goals related to user ratings and feedback, aiming for a certain average rating or customer satisfaction score.<br />
                        <b>Efficiency:</b> Increase the efficiency of matching users with service providers. This could involve reducing the average response time or time taken to complete a service request.<br />
                        <b>Service Variety:</b> Expand the range of services offered on the platform. Set a goal to add a certain number of new service categories or subcategories within a specific time period.
                    </p>
                </div>
            </div>

            <div className="row text-center" style={{marginTop:'50px', height:150, color:'#011468'}} id='contactUsAndSocials'>
                <div className="col-3 sign-in" style={{marginTop:'6px', marginLeft:'10%'}}>
                    <p><b>Are You a Partner Company:</b></p>
                </div>
                <div className="col-1 sign-in" style={{position:'relative', right:'5%'}}>
                    <a href='/company-login'>
                        <button className='btn btn-primary'>
                            Sign In
                        </button>
                    </a>
                </div>
                <div className="social-media-icons col-6">
                    <div className='container-fluid' id='download-imgs'>
                        <div className='row  justify-content-center'>    
                            <div className="linkedIn col-2"> 
                                <a href='https://www.linkedin.com/in/spanner-services'>
                                <AiFillLinkedin size={35} style={{color:'#011468'}}/>
                                </a>
                            </div>
                            <div className="instagram col-2"> 
                                <a href='https://www.instagram.com/spannerservices__/'>
                                    <AiFillInstagram size={35}  style={{color:'#011468'}}/>
                                </a>                        
                            </div>
                            <div className="twitter col-2"> 
                                <a href='https://twitter.com/SpannerServices'>
                                    <IoLogoTwitter size={35}  style={{color:'#011468'}}/>
                                </a>                        
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    );
}