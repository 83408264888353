const urls = require('./urls');
const errors = require('./errors');

export default async function registerCompanyController(email, password, name, description, cost, emergency, file, tos){
    console.log(name)
    //EMAIL CHECK
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ 
    if(!email.match(emailFormat)){
        return {
            status: 403,
            message: 'Wrong Email Format'
        }
    }
    //PASSWORD CHECK
    const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/
    if(!password.match(passwordFormat)){
        return {
            status: 403,
            message: 'Wrong Password Format'
        }
    }
    //NAME CHECK

    //TOS CHECK
    if(tos.length === 0 || tos.length === null || tos.length === undefined){
        return {
            status: 403,
            message: 'Have to offer services'
        }
    }
    //COST IS A NUMBER
    if(isNaN(cost)){
        return {
            status: 403,
            message: 'Cost should be a number'
        }
    }
    //DESCRIPTION 300 CHARS LONG
    if(description === null || description.length > 300){
        return {
            status: 403,
            message: 'Description should be 300 charachters or less'
        }
    }
    /*FILE NOT NULL
    if(file === null || file === undefined){
        return {
            status: 403,
            message: 'File can not be empty'
        }
    } */

    const convertToBase64 = async (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
        })
    }

    const base64 = {
        name: file.name,
        base64: await convertToBase64(file)
    }
    
    try{  
        const bodies = {
            email: email,
            name: name,
            password: password,
            description: description,
            typeOfServices: tos,
            rating: 0.0,
            displacementCost: cost,
            emergency: emergency,
            file: base64,
        }

        const url = urls.API_URL + "companies/createCompany"

        const response = await fetch( url,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin': '*' 
            },
            body: JSON.stringify(bodies)
        });
        
        const data = await response.json();
        console.log(data);
        if(data.status === null) return { status: 500, message: 'Network connectivity problem'}
        else if(data.status === 200) return{ status: 200, message: 'success' }
        else return { status: 500, message: 'User Already Exists.'}
        
    } catch(error){
        return {
            status:500,
            message: 'Error'
        }
    }
    
}