const urls = require('./urls');

export default async function refreshPwd(token, password){
    console.log(token)
    try{   
        const response = await fetch( urls.API_URL + "users/updatePassword/" + password,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', 
                'access-token': token,
                'Access-Control-Allow-Origin': '*' 
            },
        });
        
        const data = await response.json();
        console.log(data);
        if(response.status === 200) return 200;
        else throw 500;
    } catch(error){
        throw error.message;
    }
}