import React, { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import registerCompanyController from "../controllers/RegisterCompany";

export default function RegisterCompany(){
    const [email, setEmail] = useState(null);
    const emailRef = useRef();
    const [password, setPassword] = useState(null);
    const passwordRef = useRef();
    const [name, setName] = useState(null);
    const nameRef = useRef();
    const [description, setDescription] = useState(null);
    const descriptionRef = useRef();
    const [cost, setCost] = useState(null);
    const costRef = useRef();
    const [emergency, setEmergency] = useState(false);
    const [file, setFile] = useState(null);
    const fileRef = useRef();
    const [fontaneria, setFontaneria] = useState(false);
    const [electricista, setElectricista] = useState(false);
    const [informatica, setInformatica] = useState(false);
    const [limpieza, setLimpieza] = useState(false);
    const [carpinteria, setCarpinteria] = useState(false);
    const [pintor, setPintor] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [success, setSucces] = useState(false);


    useEffect(()=>{
        const cookies = new Cookies();
        const cookie = cookies.get('Spanner');
        if(cookie == null || cookie == undefined){
            window.location.replace("http://localhost:3000/admin-page")
        }
    },[]);

    const registerCompany = async () =>{
        const typeOfServices = await check2list();
        const result = await registerCompanyController(email, password, name, description, cost, emergency, file, typeOfServices);

        if(result.status === 403 || result.status === 500 || result.status === 400){
            setError(true);
            setErrorMsg(result.message);
        } 
        else if(result.status === 200){
            setError(false);
            setSucces(true);
            await resetFields();
        }
    }

    const resetFields = async () =>{
        await setEmail(null);
        emailRef.current.value = null

        await setPassword(null);
        passwordRef.current.value = null

        await setName(null);
        nameRef.current.value = null

        await setDescription(null);
        descriptionRef.current.value = null

        await setCost(null);
        costRef.current.value = null

        await setEmergency(false)

        await setFile(null)
        fileRef.current.value = null;

        await setFontaneria(false);
        await setElectricista(false);
        await setInformatica(false);
        await setLimpieza(false);
        await setCarpinteria(false);
        await setPintor(false);
    }

    const handleEmail = () =>{
        setEmail(emailRef.current.value);
    }

    const handlePassword = () =>{
        setPassword(passwordRef.current.value);
    }
    
    const handleName = () =>{
        setName(nameRef.current.value);
    }

    const handleDescription = () =>{
        setDescription(descriptionRef.current.value);

    }

    const handleCost = () =>{
        setCost(costRef.current.value);
    }

    const handleEmergency = () =>{
        setEmergency(!emergency)
    }

    const handlefile = (e) =>{
        setFile(e.target.files[0])
    }

    const handleFontaneria = () =>{
        setFontaneria(!fontaneria);
    }

    const handleElectricista = () =>{
        setElectricista(!electricista);
    }

    const handleInformatica = () =>{
        setInformatica(!informatica);
    }

    const handleLimpieza = () =>{
        setLimpieza(!limpieza);
    }

    const handleCarpinteria = () =>{
        setCarpinteria(!carpinteria);
    }

    const handlePintor = () =>{
        setPintor(!pintor);
    }

    const check2list = async () => {
        var result = []

        if(fontaneria) result.push('Fontaneria');
        if(electricista) result.push('Electricista');
        if(informatica) result.push('Informatica');
        if(limpieza) result.push('Limpieza');
        if(carpinteria) result.push('Carpinteria');
        if(pintor) result.push('Pintor');
        return result;
    }

    return(
        <section className="vh-100" style={{backgroundColor: "#508bfc"}}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-2-strong" style={{borderRadius: '1rem'}}>
                            <div className="card-body p-5 text-center">
                                <h3 className="mb-5">Register Company</h3>
                                { 
                                    error ? 
                                    <div className="align-middle bg-danger rounded w-60 h-20">
                                        <h5 className="text-light">{ errorMsg }</h5>
                                    </div>:
                                    <div></div>
                                }{
                                    success ?
                                    <div className="alert alert-success w-60 h-20">
                                        <h5>{ 'Company created successfully' }</h5>
                                    </div>:
                                    <div></div>
                                }
                                <div className="form-outline mb-4">
                                    <input placeholder="Company Email" type="email" ref={emailRef} onChange={handleEmail} className="form-control form-control-lg" />
                                </div>

                                <div className="form-outline mb-4">
                                    <input placeholder="Company Password" ref={passwordRef} onChange={handlePassword} type="password" className="form-control form-control-lg" />
                                </div>

                                <div className="form-outline mb-4">
                                    <input placeholder="Company Name" ref={nameRef} onChange={handleName} type="text" className="form-control form-control-lg" />
                                </div>

                                <div className="form-outline mb-4">
                                    <input placeholder="Company Description" ref={descriptionRef} onChange={handleDescription} type="text" className="form-control form-control-lg" />
                                </div>

                                <div className="form-outline mb-4">
                                    <input placeholder="Displacement Cost" ref={costRef} onChange={handleCost} type="number" className="form-control form-control-lg" />
                                </div>

                                <div className="form-outline mb-4 form-check" style={{textAlign:'left'}}>
                                    <label className="h6 form-check-label">Emergency</label>
                                    <input placeholder="Emergency" type="checkbox" checked={emergency} onChange={handleEmergency} className="form-check-input" id='emergencyCheck'/>
                                </div>

                                <div className="form-outline mb-4">
                                    <input placeholder="Image" type="file" accept="image/*" ref={fileRef} onChange={handlefile} className="form-control form-control-lg" />
                                </div>

                                <div className="form-outline mb-4" style={{textAlign:'left'}}>
                                    <h5 className='fw-bold' style={{textAlign:'left'}}>Types Of Services</h5>

                                    <div className="container">
                                        <div className="row">
                                            <div className="col form-check">
                                                <label className="h6 form-check-label">Fontaneria</label>
                                                <input placeholder="Emergency" onChange={handleFontaneria} checked={fontaneria} type="checkbox" className="form-check-input" id='fontaneria'/>
                                            </div>
                                            <div className="col form-check">
                                                <label className="h6 form-check-label">Electricista</label>
                                                <input placeholder="Emergency"  onChange={handleElectricista} checked={electricista} type="checkbox" className="form-check-input" id='Electricista'/>
                                            </div>
                                            <div className="col form-check">
                                                <label className="h6 form-check-label">Informatica</label>
                                                <input placeholder="Emergency" onChange={handleInformatica} checked={informatica} type="checkbox" className="form-check-input" id='informatica'/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col form-check">
                                                <label className="h6 form-check-label">Limpieza</label>
                                                <input placeholder="Emergency" onChange={handleLimpieza} checked={limpieza} type="checkbox" className="form-check-input" id='limpieza'/>
                                            </div>
                                            <div className="col form-check">
                                                <label className="h6 form-check-label">Carpinteria</label>
                                                <input placeholder="Emergency" onChange={handleCarpinteria} checked={carpinteria} type="checkbox" className="form-check-input" id='carpinteria'/>
                                            </div>
                                            <div className="col form-check">
                                                <label className="h6 form-check-label">Pintor</label>
                                                <input placeholder="Emergency" onChange={handlePintor} checked={pintor} type="checkbox" className="form-check-input" id='pintor'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <button className="btn btn-primary btn-lg btn-block" onClick={registerCompany}>Register Company</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}