import React, { useEffect, useState } from "react";
import '../Styles/CompanyChat.css'
import { RxAvatar } from 'react-icons/rx';
import getUser from "../controllers/UserController";

export default function ChatBox({friendId, changeChat}){
    const [friendData, setFriendData] = useState({name:''}); 

    useEffect(()=>{
        const init = async () =>{
            const data = await getUser(friendId);
            console.log(data.name);
            setFriendData(data);
            changeChat(data.name, friendId)
        }

        init();
    },[])
    
    return( 
        <div onClick={() => changeChat(friendData.name, friendId)} className="person-box">
            <RxAvatar
                className="avatar-icon"
                color="#011468"
            />
            <p className={true ? "person-name" : "person-pending-name"}>{friendData.name}</p>
            {!true && <div className="pending-icon"/>}
        </div>      
    );
}