import React, { useState, useRef, useEffect} from "react";
import adminLogIn from "../controllers/AdminLogInController";
import Cookies from 'universal-cookie';

export default function AdminPage(){
    const [password, setPassword] = useState();
    const passwordRef = useRef(null);
    const [email, setEmail] = useState();
    const emailRef = useRef(null);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [conditions, setConditions] = useState(false);
    const [emailFormat, setEmailFormat] = useState(false);

    useEffect(()=>{
        const cookies = new Cookies();
        const cookie =cookies.get('Spanner');
        if(cookie == 'active'){
            window.location.replace("http://localhost:3000/register-company")
        }
    })

    const logIn = async () =>{
        if(conditions && emailFormat){
            try{
                const ret = await adminLogIn(email, password);
                if(ret === 200){
                    const cookies = new Cookies();
                    cookies.set('Spanner', 'active',  {
                        path:'/',
                        maxAge: 3600
                    });

                    window.location.replace("http://localhost:3000/register-company")
                }
                else if(ret === 402){
                    setError(true);
                    setErrorMsg("Wrong Password")                
                }
                else if(ret === 404){
                    setError(true);
                    setErrorMsg("Admin don't exists")
                } 
                else if(ret === 500){
                    setError(true);
                    setErrorMsg("Network Error")                
                }

            } catch(error){
                setError(true);
            }
        }
    }

    const handleEmail = () =>{
        setEmail(emailRef.current.value);

        const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        
        if(!emailRef.current.value.match(emailFormat)){
            setEmailFormat(false)
        } else{
            setEmailFormat(true)
        }
    }

    const handlePassword = () =>{
        setPassword(passwordRef.current.value)

        const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/
        
        if(!passwordRef.current.value.match(passwordFormat)){
            setConditions(false);
        } else {
            setConditions(true);
        }
    }

    return(
        <section class="vh-100" style={{backgroundColor: "#508bfc"}}>
            <div class="container py-5 h-100">
                <div class="row d-flex justify-content-center align-items-center h-100">
                    <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div class="card shadow-2-strong" style={{borderRadius: '1rem'}}>
                            <div class="card-body p-5 text-center">
                                <h3 class="mb-5">Admin Log In</h3>
                                {error ? 
                                    <div className="align-middle bg-danger rounded w-60 h-20">
                                        <h5 className="text-light">{ errorMsg }</h5>
                                    </div>:
                                    <div></div>
                                }{
                                    !conditions ? 
                                    <div className="align-middle bg-danger rounded w-60 h-20">
                                        <h5 className="text-light">Wrong Password Format</h5>
                                    </div>:
                                    <div></div>
                                }{
                                    !emailFormat ? 
                                    <div className="align-middle bg-danger rounded w-60 h-20">
                                        <h5 className="text-light">Wrong Email Format</h5>
                                    </div>:
                                    <div></div>
                                }
                                <div class="form-outline mb-4">
                                    <input ref={emailRef} onChange={handleEmail} placeholder="Email" type="email" class="form-control form-control-lg" />
                                </div>

                                <div class="form-outline mb-4">
                                    <input ref={passwordRef} onChange={handlePassword} placeholder="Repeat Password" type="password" class="form-control form-control-lg" />
                                </div>
 
                                <button onClick={logIn} class="btn btn-primary btn-lg btn-block" type="submit">Log In</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}